.rows {
  display: flex;
  // flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.lenta__main .swiper-container {
  padding-bottom: 60px;
  padding: 20px;
}
