.blackAndWhite {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}
.blackAndWhiteInvert, .blackAndWhiteInvert img, .blackAndWhiteInvert embed, .blackAndWhiteInvert video {
    -webkit-filter: grayscale(100%) invert(100%);
    -moz-filter: grayscale(100%) invert(100%);
    -ms-filter: grayscale(100%) invert(100%);
    -o-filter: grayscale(100%) invert(100%);
    filter: grayscale(100%) invert(100%);
}
.icon_accessibility:hover {
    cursor: pointer;
}
.specialViewArea {
    padding: 10px 20px 20px 20px;
    width: 265px;
    right: -10px;
    top: 30px;
    color: #000;
    z-index: 9999 !important;  
}
.appearance {
    margin-bottom: 10px;
    clear: both;
    display: table;
    width: 100%}
.squareBox {
    width: 50px;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    font-size: 27px;
    font-weight: bolder;
    cursor: pointer;
    background: #828282;
    border: 2px solid #353535;
    color: #fff;
}
.specialTitle {
    font-size: 18px;
}
.squareAppearances {
    float: left;
    margin-right: 5px;
}
.squareBox.spcNormal {
    background: #0057a8;
}
.squareBox.spcWhiteAndBlack {
    background: #828282;
    border-color: #353535;
}
.squareBox.spcDark {
    background: #3e3e3e;
    border-color: #777;
}
.fontSmaller {
    font-size: 24px;
}
.fontBigger {
    font-size: 26px;
}
.defaultSlider {
    height: 4px!important;
    background: #b7b7b7;
    border: 0;
}
.defaultSlider span {
    border-radius: 50%;
    outline: 0;
    background: #fff!important;
    cursor: pointer!important;
    width: 25px!important;
    height: 25px!important;
    top: -11px!important;
    box-shadow: 0 0 5px #ccc;
    -webkit-box-shadow: 0 0 5px #ccc;
    -moz-box-shadow: 0 0 5px #ccc;
    -o-box-shadow: 0 0 5px #ccc;
    -ms-border-shadow: 0 0 5px #ccc;
    margin-bottom: 10px;
}
.sliderZoom, .sliderText {
    margin-bottom: 20px;
}
.defaultSlider span:focus, .defaultSlider span:hover {
    border-color: #d3d3d3;
}
.defaultSlider .ui-slider-range {
    background: #0057a8;
}
