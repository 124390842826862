$blue: #0053d3;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

@mixin desk {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin ipad_pro {
  @media (max-width: 1299px) {
    @content;
  }
}

@mixin ipad {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin mini {
  @media (max-width: 350px) {
    @content;
  }
}

@mixin tran15 {
  transition: all 0.15s linear;
}

@mixin tran30 {
  transition: all 0.3s linear;
}

@mixin img_cov {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

@mixin img_con {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
}
.page_height {
  min-height: 40vh;
}
.card-textt {
  // height: 84px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

a:hover {
  .img_net {
    color: $blue;
  }
}
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  flex-direction: column;

  p {
    font-size: 16px;
  }
}

.svg-map__location {
  fill: #0f4c82 !important;
}

.svg-map__location:focus,
.svg-map__location:hover {
  fill: #0b65b5 !important;
  outline: none;
}

body .card-news .card-body .card-text-project {
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
  padding-bottom: 13px;
  margin-bottom: 30px;
  border-bottom: 1px dashed #000;

  p {
    font-size: 14px;
  }
}

body,
* {
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 21px;

  @include desk {
    font-size: 16px;
  }
}

.container {
  max-width: 1370px;
  width: 100%;

  @include mobile {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.btn {
  padding: 0 !important;
}

a {
  transition: all 0.15s;
  text-decoration: none !important;
}

svg {
  path {
    transition: all 0.15s;
  }
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

input,
textarea {
  outline: none;
}

button {
  outline: none !important;
  border: 0;
  transition: all 0.15s;
}

section.swiper-container.swiper-general {
  position: relative;
  z-index: 2;

  .swiper-slide {
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;

    @include ipad_pro {
      min-height: 70vh;
    }

    @include ipad {
      min-height: 80vh;
    }

    @include mobile {
      min-height: 100vh;
      //padding-bottom: 50px;
    }

    > * {
      position: relative;
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.81) 0%,
        rgba(3, 3, 3, 0.41) 13.28%,
        rgba(158, 158, 158, 0) 63.01%,
        // #f2f4f6 100%
      );

      @include mobile {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.81) 0%,
          rgba(3, 3, 3, 0.41) 43.28%,
          rgba(158, 158, 158, 0.85) 83.01%,
          #262626 100%
        );
      }
    }

    h2 {
      font-weight: 800;
      font-size: 36px;
      line-height: 1.2;
      text-align: center;
      color: #ffffff;
      margin-bottom: 20px;
      max-width: 1000px;
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
      @include desk {
        font-size: 30px;
        line-height: 1.3;
        padding-left: 75px;
        padding-right: 75px;
      }
      @include mobile {
        font-size: 20px;
        line-height: 1.3;
        padding: 0;
      }
    }

    p {
      display: -webkit-box;
      max-width: 100%;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 0px;
      @include desk {
        margin-bottom: 30px;
        padding-left: 150px;
        padding-right: 150px;
      }

      @include mobile {
        font-size: 16px;
        line-height: 1.3;
        padding: 0;
      }
    }

    .btn-white {
      position: absolute;
      left: 50%;
      bottom: 155px;
      transform: translateX(-50%);
      @include desk {
        bottom: 90px;
      }
    }
  }

  .navigation {
    position: absolute;
    top: 50%;
    z-index: 2;
    left: 0;
    right: 0;

    @include mobile {
      display: none;
    }

    > div {
      position: absolute;
      left: 120px;
      right: auto;
      cursor: pointer;

      &.swiper__button__next {
        left: auto;
        right: 120px;
      }
    }
  }

  .swiper-pagination {
    bottom: 60px;
    @include desk {
      bottom: 30px;
    }

    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid #f2f1f1;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background-color: #fff;
      }
    }
  }
}

.btn-white {
  background: #ffffff;
  border: 2px solid #0f4c82;
  border-radius: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #0f4c82;
  display: inline-block;
  padding: 13px 46px;

  @include desk {
    font-size: 18px;
    line-height: 24px;
    padding: 10px 30px;
  }

  &:hover {
    box-shadow: 0 0 10px #0f4c82;
  }
}

.btn-details {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #0f4c82;
  display: inline-block;
  padding: 3px 30px;
  background: #ffffff;
  border: 1px solid #0f4c82;
  border-radius: 8px;
}

.btn-details:hover {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffff !important;
  display: inline-block;
  padding: 3px 30px;
  background: #0f4c82;
  border: 1px solid #ffff;
  border-radius: 8px;
}

.btn-details:active {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffff;
  display: inline-block;
  padding: 3px 30px;
  background: #0f4c82;
  border: 1px solid #ffff;
  border-radius: 8px;
}

.btn-all {
  background: #0f4c82;
  border-radius: 10px;
  padding: 13px 63px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;

  @include desk {
    font-size: 18px;
    line-height: 24px;
    padding: 10px 30px;
  }

  &:hover {
    color: #fff;
    background: #053b6a;
  }
}

.btn-exact {
  background: #9bd2ff;
  border-radius: 48px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 10px 55px;

  &:hover {
    color: #000000;
    background: #4f94cd;
  }
}

.btn-send {
  background: #0d47a1;
  border-radius: 8px;
  padding: 8px 60px;
  display: inline-block;
  border: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    box-shadow: 0 0 10px rgba(#0f4c82, 0.6);
  }
}

h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  color: #0f4c82;
  margin-bottom: 40px;

  @include desk {
    font-size: 30px !important;
    line-height: 1.3 !important;
  }
}

.breadcrumb {
  background: none;
  padding: 0;
  margin-bottom: 30px;

  li {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #002444;
    mix-blend-mode: normal;

    &.active {
      color: #002444;
    }

    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #2196f3;
      mix-blend-mode: normal;
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: #2196f3;
  font-weight: 600;
  transform-origin: center;
  transform: scaleY(1.5) translateY(1px);
  font-size: 12px;
}

.row.row-10 {
  margin-left: -10px;
  margin-right: -10px;

  > div {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row.row-60 {
  margin-left: -30px;
  margin-right: -30px;

  @include desk {
    margin-left: -15px;
    margin-right: -15px;
  }

  > div {
    padding-left: 30px;
    padding-right: 30px;

    @include desk {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

section {
  padding: 40px 0;
}

.projects {
  background: rgba(15, 76, 130, 0.13);
  position: relative;
  padding: 0;

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: 0.15;
  }

  h2 {
    margin-bottom: 60px;
    padding-top: 50px;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .projects__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
    @include desk {
      padding: 0 90px 50px;
    }
    @include ipad_pro {
      padding: 0 0 50px;
    }

    @include mobile {
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: center;
    }

    .item {
      width: 250px;
      height: 250px;
      background: #ffffff;
      border: 1px solid #0f4c82;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      border-radius: 50%;
      padding: 20px;

      @include desk {
        width: 230px;
        height: 230px;
      }

      @include ipad_pro {
        width: 210px;
        height: 210px;
      }

      @include ipad {
        width: 180px;
        height: 180px;
        padding: 10px;
      }

      @include mobile {
        margin: 30px auto;
        width: 150px;
        height: 150px;
      }

      img {
        object-fit: contain;
        object-position: center;
        height: 44px;
        width: auto;
        margin-bottom: 20px;

        @include ipad_pro {
          height: 30px;
          margin-bottom: 10px;
        }

        @include mobile {
          height: 24px;
        }
      }

      h4 {
        font-weight: 700;
        font-size: 36px;
        line-height: 49px;
        text-align: center;
        margin-bottom: 12px;

        @include desk {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 5px;
        }

        @include mobile {
          font-size: 24px;
        }
      }

      p {
        @include mobile {
          font-size: 12px;
          line-height: 1.2;
          font-weight: 700;
        }
      }
    }
  }
}

section.in__map {
  position: relative;
  background-size: auto 120%;
  background-position: center;
  background-repeat: no-repeat;

  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(
      259.53deg,
      rgba(207, 235, 255, 0.2) 9.53%,
      rgba(155, 213, 255, 0) 96.27%
    );
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .row {
    align-items: flex-start;
  }

  .map__uzbekistan {
    width: 100%;
    height: auto;
  }
}

.swiper-map-country {
  background: #ffffff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 18px;

  @include mobile {
    padding-left: 0;
    padding-right: 0;
  }

  .swiper-slide {
    img {
      width: 98%;
      margin: auto;
      height: 210px;
      display: block;
      cursor: pointer;
      object-fit: contain;
    }
  }

  .navigation {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    top: 50px;
    right: 0;
    left: 0;
    z-index: 2;

    @include mobile {
      top: 80px;
    }

    div {
      @include mobile {
        border-radius: 50%;
        background-color: #eee;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.text-card-style {
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #0f4c82;
    margin-bottom: 20px;
    @include mobile {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  p {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    padding-left: 30px;
    padding-right: 30px;

    span {
      color: #0d47a1;
      font-size: inherit;
    }
  }
}

section.tender {
  .text-card-style {
    background: #f9f9f9;
    border-radius: 15px;
    margin-bottom: 0px;
    padding: 25px 40px 80px;
    height: 100%;
    position: relative;
    @include mobile {
      padding: 10px 0px 50px;
    }

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    h4 {
      font-size: 16px;
      line-height: 22px;
    }

    p {
      margin-bottom: 15px;
      font-size: 15px;
      line-height: 18px;
      display: block;
    }

    a {
      float: right;
    }

    button {
      float: right;
      position: absolute;
      z-index: 2;
      right: 35px;
      bottom: 35px;
      @include mobile {
        bottom: 15px;
        right: 15px;
      }
    }
  }
}

.last__news {
  background-color: #fafdff;

  .swiper-container {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% + 48px);
    padding-bottom: 60px;

    @include desk {
      margin-left: -14px;
      margin-right: -14px;
      padding-left: 14px;
      padding-right: 14px;
      width: calc(100% + 28px);
    }
  }

  .swiper-pagination {
    bottom: 0px;
    z-index: 3;

    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid #9bd2ff;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background-color: #9bd2ff;
      }
    }
  }
}

body .card-news {
  width: 100%;
  margin: 0;
  background: #ffffff;
  border: 1px solid #cecaca;
  box-shadow: 0px 1px 24px 8px rgba(20, 20, 20, 0.08);
  border-radius: 15px;
  overflow: hidden;

  &.with__project {
    .card-img-top {
      border: 1px solid #2196f3;
      border-radius: 15px;

      &:after {
        display: none;
      }
    }

    .card-body {
      .card-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #0f4c82;
        margin-bottom: 20px;

        h5 {
          display: -webkit-box;
          max-width: 100%;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 84px;
          -webkit-line-clamp: 3;
        }
      }
    }
  }

  .card-img-top {
    position: relative;

    border-radius: 15px;
    overflow: hidden;

    &:hover {
      &:after {
        opacity: 1;
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(36, 140, 223, 0.3);
      z-index: 1;
      transition: all 0.15s;
      opacity: 0;
    }

    > img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      height: 203px;

      @include mobile {
        height: 180px;
        object-fit: cover;
      }
    }

    .time-news {
      position: absolute;
      right: 0;
      bottom: 0;
      background: #9bd2ff;
      border-radius: 48px 0px;
      border-bottom-right-radius: 12px;
      padding: 10px 50px 10px 40px;
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.02em;
      z-index: 2;

      img {
        margin-right: 8px;
      }
    }
  }

  .card-body {
    padding: 24px 24px 40px;
    // max-height: 428px;
    .card-title {
      display: -webkit-box;
      max-width: 100%;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 84px;
      -webkit-line-clamp: 3;
      font-weight: 600;
      font-size: 24px;
      line-height: 160%;
      letter-spacing: 0.02em;
      margin-bottom: 5px;

      @include mobile {
        font-size: 16px !important;
        line-height: 20px !important;
        height: 60px !important;
        margin-bottom: 10px;
      }
    }

    .card-text {
      display: -webkit-box;
      max-width: 100%;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 84px;
      -webkit-line-clamp: 3;
      margin-bottom: 20px;
      font-size: 15px;

      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.02em;
      padding-bottom: 13px;
      margin-bottom: 30px;
      border-bottom: 1px dashed #000000;

      @include mobile {
        font-size: 14px;
        line-height: 18px;
        height: 74px;
        &:after {
          content: "";
          z-index: 2;
          position: relative;
          display: block;
          padding-top: 20px;
          height: 0px;
          width: 100%;
          background: #fff;
        }
      }

      p,
      span {
        display: -webkit-box;
        max-width: 100%;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // height: 78px;
        -webkit-line-clamp: 4;
        font-size: inherit;
        margin-bottom: 20px;
      }
    }
  }
}

.contact-form {
  .form-group {
    position: relative;
    margin-bottom: 35px;

    label {
      position: absolute;
      left: 0;
      bottom: 0px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.54);
      transition: all 0.15s;
    }
  }

  input {
    width: 100%;
    border: 0;
    transition: all 0.15s;
    border-bottom: 2px solid rgba(0, 0, 0, 0.38);
    padding: 10px 0;

    &:focus {
      border-bottom-color: #0d47a1;

      & + label {
        color: #0d47a1;
      }
    }

    &:focus + label,
    &:valid + label {
      transform: translateY(-30px);
    }
  }

  textarea {
    width: 100%;
    border: 0;
    height: 106px;
    transition: all 0.15s;
    border-bottom: 2px solid rgba(0, 0, 0, 0.38);
    padding: 10px 0;
    resize: none;

    &:focus {
      border-bottom-color: #0d47a1;
    }

    &:focus::placeholder {
      color: #0d47a1;
    }
  }

  .btn-send {
    float: right;
    min-width: 360px;

    @include mobile {
      float: none;
      min-width: 100%;
      margin-bottom: 40px;
    }
  }
}

header,
footer,
.news__page__context,
.tender__page__context,
.projects__page__context,
.detail__w {
  .container {
    max-width: 1640px;

    @include desk {
      max-width: 1370px;
    }
  }
}

body {
  &.homepage {
    header {
      &.top0 {
        background-color: transparent;

        .search__main {
          form {
            background-color: transparent;
            border: 1px solid #fff;
            border-radius: 12px;
            top: 40px;
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(5px);

            input {
              background-color: transparent;
            }
          }
        }
      }

      &.fixed + section {
        margin-top: 0 !important;
      }
    }
  }
}

header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  padding: 20px 0;
  transition: all 0.15s;
  min-height: 140px;

  @include desk {
    padding: 10px 0;
    min-height: 80px;
  }

  @include ipad_pro {
    max-width: 100vw;
  }

  @include mobile {
    height: 80px;
  }

  .hamburger {
    display: none;

    @include ipad_pro {
      display: block;
    }
  }

  .logo {
    img {
      max-width: 200px;

      @include desk {
        max-width: 180px;
      }

      @include mobile {
        max-width: 120px;
      }
    }
  }

  &.fixed {
    background: #002444;
  }

  &:not(.fixed) {
    .dropdown-menu {
      background: none;
      border: 1px solid #fff;
      border-radius: 5px;
    }
  }
  .tops {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .hdr__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    width: 100%;

    @include desk {
      padding-bottom: 0;
    }
  }

  .hdr__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include ipad_pro {
      display: none !important;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      li {
        margin-left: 45px;

        @include desk {
          margin-left: 25px;
        }

        a {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          color: #ffffff;

          @include desk {
            font-size: 16px;
            font-weight: 700;
          }

          &.active {
            color: #2196f3;
          }

          box-shadow: none !important;
        }
      }
    }
  }

  .tops {
    @include ipad_pro {
      margin-left: auto;
      margin-right: 30px;
    }
  }

  .drops {
    position: relative;
    z-index: 3;

    &.language {
      .body_dropdown {
        min-width: 150px;

        button {
          width: 100%;
          background: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }

    @include mobile {
      margin-left: 15px;
    }

    &.eyes {
      @include ipad_pro {
        display: none;
      }

      span {
        background-image: none !important;
      }

      .body_dropdown {
        padding: 20px;

        input {
          width: 100%;
        }
      }
    }

    img {
      max-width: 30px;
      margin-right: 10px;

      @include mobile {
        max-width: 20px;
      }
    }

    @include ipad_pro {
    }

    &:hover {
      .body_dropdown {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
      }
    }

    span {
      display: inline-flex;
      align-items: center;
      padding-right: 20px;
      cursor: pointer;
      padding-top: 11px;
      padding-bottom: 11px;
      background: url("../images/arrow_language.svg") no-repeat right top 20px;
      background-size: 14px auto;
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      color: #fff;

      @include mobile {
        font-size: 12px;
        padding-right: 18px;
        background: url("../images/arrow_language.svg") no-repeat right top 22px;
      }
    }

    .body_dropdown {
      position: absolute;
      top: 100%;
      left: -10px;
      right: -10px;
      padding-left: 10px;
      padding-right: 10px;
      background: #ffffff;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
      flex-direction: column;
      display: flex;
      transition: all 0.1s linear;
      border-top: 2px solid #0053d3;
      opacity: 0;
      pointer-events: none;
      transform: translateY(10px);

      a {
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 40px;
        color: #000;

        @include mobile {
          font-size: 16px;
          line-height: 30px;
        }

        &:hover {
          color: #0053d3;
        }
      }
    }
  }

  .hdr__top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 0;

    @include desk {
      padding: 0;
    }

    @include ipad_pro {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    @include ipad {
    }

    button {
      display: flex;
      align-items: center;
      padding: 5px 10px;
    }

    .eye__glass,
    .symbol {
      display: inline-flex;
      align-items: center;
      font-size: 11px;
      line-height: 13px;
      color: #ffffff;
      margin-right: 30px;

      &:hover {
        opacity: 0.8;
      }

      img,
      svg {
        margin-right: 10px;
      }
    }
  }

  .search__main {
    margin-left: 45px;
    position: relative;

    .search__btn {
      cursor: pointer;
      position: relative;
      z-index: 2;
    }

    .toggle__body {
      position: absolute;
      top: 80px;
      right: -30px;
      min-width: 500px;
      transform: translateY(-40px);
      opacity: 0;
      transition: all 0.15s;
      z-index: -1;

      @include desk {
        top: 45px;
        right: -10px;
      }

      &.active {
        transform: translateY(0px);
        opacity: 1;
        z-index: 1;
      }
    }

    form {
      background: #002444;
      display: flex;
      align-items: center;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      overflow: hidden;
      @include desk {
        border-radius: 12px;
      }

      input {
        border: 0;
        width: 100%;
        height: 60px;
        background-color: #002444;
        color: #fff;
        padding-left: 20px;
        @include desk {
          height: 50px;
        }
      }

      button {
        border: 0;
        background: none;
        padding-right: 28px;

        svg {
          width: 20px;

          path {
            stroke: #fff;
          }
        }
      }
    }
  }

  .setting {
    margin-left: 30px;
    padding-left: 30px;
    border-left: 3px solid #fff;

    img {
      max-width: 20px;

      @include desk {
      }
    }

    .dropdown-toggle {
      &::after {
        display: none !important;
      }
    }
  }

  .dropdown-menu {
    background: #002444;
    border-radius: 0px 0px 20px 20px;

    a {
      font-size: 15px;
      border-bottom: 2px solid #fff;
      padding-bottom: 5px;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.mobile__menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.3s;
  opacity: 0;
  z-index: 12;
  pointer-events: none;

  .swiper {
    display: none;
    @include ipad {
      display: block;
      margin-bottom: 15px;
    }
  }

  &.open {
    pointer-events: auto;
    opacity: 1;

    .body {
      transform: translate(0);
    }
  }

  .body_dropdown {
    display: flex;
    align-items: center;
    padding-top: 50px;

    a,
    span {
      font-size: 16px !important;
      font-weight: 400;
      padding: 3px 10px;
      padding-left: 0;
      border: 2px solid transparent;
    }

    span {
      order: -1;
      font-weight: 600;
      color: #111 !important;
    }
  }

  &:after {
    content: "";
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    background: url("../images/close.svg") no-repeat center;
    background-size: contain;
  }

  .body {
    height: 100vh;
    background-color: #002444;
    padding: 50px 20px;
    max-width: 680px;
    width: 100%;
    transform: translateX(-100%);
    transition: all 0.5s;

    @include ipad_pro {
      max-height: 100vh;
      overflow-y: auto;
    }

    @include ipad {
      max-width: 400px;
    }

    @include mobile {
      max-width: 300px;
      padding-top: 20px;
    }

    ul {
      li {
        margin-bottom: 30px;

        @include mobile {
          margin-bottom: 10px;
        }

        a {
          font-size: 24px;
          line-height: 1.4;
          color: #fff;

          &:hover {
            background-color: transparent;
          }

          @include mobile {
            font-size: 16px;
          }

          &.active {
            color: #2196f3;
          }
        }
      }

      .drop-menu {
        a {
          @include mobile {
            font-size: 14px;
          }
        }

        @include ipad_pro {
          margin-top: 20px;
        }

        @include mobile {
          margin-top: 10px;
        }
      }
    }

    .body_dropdown {
      display: flex;
      align-items: center;
      padding-bottom: 50px;

      a {
        font-size: 26px;
        display: inline-flex;
        align-items: center;
        margin-right: 0px;
        padding: 3px 10px;
        border: 2px solid transparent;
        border-radius: 6px;
        color: #000;
        font-weight: 700;

        &.actived {
          color: #111;
          border: 2px solid #111;
        }
      }

      img {
        max-width: 24px;
        margin-right: 10px;
        display: none;
      }
    }

    form {
      border-bottom: 2px solid #fff;
      display: flex;
      align-items: center;
      margin-bottom: 50px;

      @include mobile {
        margin-bottom: 15px;
      }

      input {
        background-color: transparent;
        border: 0;
        width: 100%;
        height: 40px;
        color: #fff;
        font-size: 20px;

        @include mobile {
          font-size: 16px;
        }
      }

      button {
        background-color: transparent;
        border: 0;
        outline: none;
        height: 40px;

        svg {
          @include mobile {
            width: 16px;
            height: auto;
          }

          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}

.dropdown-toggle::after {
  background: url("../images/arrow.svg") no-repeat center;
  background-size: contain;
  border: 0 !important;
  width: 11px;
  height: 14px;
  transform: translateY(6px);
  opacity: 1;

  @include ipad_pro {
    display: none;
  }
}

footer {
  background: #002444;
  padding: 60px 0 10px;

  @include desk {
    padding: 50px 0 30px;
  }

  @include mobile {
    text-align: center;
    padding-top: 30px;
  }

  a.logo {
    margin-bottom: 25px;
    display: inline-block;
    margin-top: -40px;

    @include mobile {
      margin-top: 0;
    }

    img {
      max-width: 180px;
      height: auto;

      @include desk {
        max-width: 160px;
      }
    }
  }

  .logo_subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-width: 70%;
    margin-bottom: 50px;
    color: #ffffff;

    @include ipad_pro {
      max-width: 100%;
    }
    @include mobile {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }

  .name-ftr-bottom {
    span {
      font-size: 14px;
    }
  }

  * {
    color: #fff;
  }

  .sitemap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mobile {
      text-align: center;
      align-items: center;
    }

    li {
      margin-bottom: 20px;

      a {
        display: inline-flex;
        align-items: center;
        color: #fff;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #ffffff;

        @include mobile {
          line-height: 1.2;
        }

        img {
          margin-right: 12px;
          width: 15px;
          height: auto;
        }
      }
    }
  }

  .list__page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mobile {
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }

    li {
      margin-bottom: 15px;

      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #ffffff;
      }
    }
  }

  .socials {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @include ipad {
      margin-bottom: 30px;
      margin-top: 30px;
    }

    @include mobile {
      justify-content: center;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 14px;
      border-radius: 50%;
      background-color: #fff;
      padding: 7px;

      &:hover {
        transform: translateY(-4px);
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        object-position: center;

        @include desk {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .search-form {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 8px;

    input {
      border: 0;
      background-color: transparent;
      height: 42px;
      padding: 0 15px;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #000;
      width: 100%;

      @include mobile {
        height: 40px;
        font-size: 15px;
      }

      &::placeholder {
        color: rgba(#0f4c82, 0.3);
      }
    }

    button {
      border: 0;
      background: #9bd2ff;
      border-radius: 0px 8px 8px 0px;
      height: 42px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      @include mobile {
        height: 40px;
      }

      svg {
        @include mobile {
          max-width: 20px;
        }
      }
    }
  }

  .ftr__bottom {
    padding-top: 10px;

    @include ipad {
      text-align: center;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      @include mobile {
        font-size: 13px !important;
      }
    }
  }
}

section.about__page__context {
  img {
    width: 100%;
    height: auto;

    @include ipad {
      margin-bottom: 20px;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);

    @include mobile {
      font-size: 16px;
      word-break: break-word;
    }
  }
}

body:not(.homepage) {
  header.fixed {
    & + .mobile__menu + section {
      margin-top: 158px;

      @include desk {
        margin-top: 99px;
      }

      @include ipad_pro {
        margin-top: 84px;
      }

      @include mobile {
        margin-top: 80px;
      }
    }
  }
}

section.title_page {
  position: relative;
  text-align: center;
  background-position: center;
  background-size: cover;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 36, 68, 0.52),
      rgba(0, 36, 68, 0.52)
    );
  }

  &.detail__w {
    h1 {
      text-align: left;
      max-width: 100%;

      img {
        margin-left: 30px;
        margin-right: 30px;
        width: 300px;
        height: 300px;
        flex: 0 0 300px;
        object-fit: contain !important;
        background-color: #fff;
        object-position: center;
        border-radius: 50%;
        overflow: hidden;
        @include desk {
          margin-top: 15px;
          width: 200px;
          height: 200px;
          flex: 0 0 200px;
        }
      }
    }
  }

  h1 {
    font-weight: 700;
    font-size: 42px;
    line-height: 70px;
    text-align: center;
    color: #f2f2f2;
    position: relative;
    z-index: 2;
    min-height: 127px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include desk {
      font-size: 30px;
      max-width: 750px;
      margin-left: auto;
      line-height: 1.3;
      margin-right: auto;
    }

    @include mobile {
      font-size: 24px;
      line-height: 1.3;
      flex-wrap: wrap;
    }
  }
}

.lead__items {
  .item {
    background: rgba(0, 36, 68, 0.03);
    border: 1px solid #2196f3;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 10px 20px;

    @include mobile {
      flex-wrap: wrap;
    }

    .img {
      margin-right: 50px;

      @include desk {
        margin-right: 20px;
      }

      @include mobile {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 10px;
      }

      img {
        width: 135px;
        height: auto;
        @include desk {
          width: 100px;
        }
      }
    }

    .text {
      h4 {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 12px;

        @include desk {
          font-size: 18px;
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.54);

        @include desk {
          font-size: 15px;
        }
      }

      .btn-details {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
        color: #0d47a1;
      }
    }

    .robita {
      margin-bottom: 20px;

      a {
        display: inline-flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.87);
        @include desk {
          font-size: 14px;
        }

        &:hover {
          color: #2196f3;
        }

        img {
          margin-right: 9px;
          height: 12px;
          width: auto;
        }
      }
    }
  }
}

.modal-dialog {
  max-width: 1200px;
  width: 100%;

  @include ipad_pro {
    max-width: 900px;
  }

  @include mobile {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

.modal__style {
  width: 100%;

  .modal-body {
    padding: 35px;

    @include mobile {
      padding: 15px;
    }
  }

  .close {
    position: absolute;
    right: 25px;
    top: 20px;
    opacity: 1;
    height: 25px;
    width: 25px;

    @include mobile {
      right: 10px;
      top: 10px;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .main-modal {
    display: flex;
    align-items: flex-start;

    @include mobile {
      flex-wrap: wrap;
    }

    img {
      width: 135px;
      margin-right: 35px;
      height: auto;
      margin-top: 50px;

      @include mobile {
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 10px;
      }
    }

    .text {
      h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        color: #0d47a1;
        margin-bottom: 20px;
      }

      h4 {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 20px;
      }

      p {
        font-size: 17px;
        line-height: 24px;
        text-align: justify;
        color: rgba(0, 0, 0, 0.54);

        @include mobile {
          font-size: 16px;
          line-height: 1.2;
        }
      }
    }
  }

  .btn-details {
    float: right;
    margin-top: 50px;

    @include mobile {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

.my__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  li {
    margin: 0;

    a {
      font-weight: 500;
      font-size: 24px;
      line-height: 1;
      border-radius: 50%;
      width: 43px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 43px;
      border: 0;
      color: #000000;
      outline: none;
      box-shadow: none !important;

      @include desk {
        width: 34px;
        height: 34px;
        font-size: 18px;
      }

      &:hover {
        background-color: transparent;
      }

      &.active {
        color: #0d47a1;
        background-color: #ddeffd;
      }

      &.prev,
      &.next {
        &.disabled {
          svg {
            fill: #cccccc;
          }
        }

        svg {
          fill: #2196f3;
          max-width: 10px;

          @include mobile {
          }
        }
      }
    }
  }
}

section.award__section {
  padding-bottom: 60px;
}

.award__items {
  margin-bottom: 60px;

  .item {
    display: flex;
    background: rgba(0, 36, 68, 0.03);
    border: 1px solid #2196f3;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 20px;

    @include mobile {
      flex-wrap: wrap;
    }

    img {
      width: 280px;
      flex: 0 0 280px;
      height: auto;
      margin-right: 40px;
    }

    h5 {
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 10px;
      margin-top: 20px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

.top-section {
  justify-content: space-between;
  margin-bottom: 30px;

  @include mobile {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .breadcrumb {
    margin-bottom: 0;

    @include mobile {
      margin-bottom: 30px;
    }
  }

  .sort {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #000000;
    padding-right: 20px;
  }

  select {
    cursor: pointer;
    outline: none !important;
    border: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 260%;
    letter-spacing: 0.02em;
    color: #000000;
  }
}

.side__menu {
  background: #f2f4fb;
  border-radius: 10px;
  position: sticky;
  top: 150px;
  padding: 30px;
  margin-top: 58px;

  @include desk {
    padding: 20px;
  }

  h5 {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #2a333e;
    padding: 20px 10px;

    @include desk {
      padding: 12px 8px;
    }
  }

  ul {
    li {
      padding: 20px 10px;
      border-bottom: 1px solid #e5e5e5;

      @include desk {
        padding: 12px 8px;
      }

      a {
        font-weight: 400;
        font-size: 17px;
        line-height: 23px;
        color: #2a333e;

        @include desk {
          font-size: 15px;
        }

        &.active {
          color: #0d47a1;
        }
      }
    }
  }
}

.count-view {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    margin-left: 30px;

    img {
      margin-right: 7px;
    }
  }
}

.fancybox-button svg path {
  fill: #fff;
}

.fancybox-infobar {
  span {
    color: #fff;
  }
}

.fancybox-caption,
.fancybox-infobar,
.fancybox-navigation .fancybox-button,
.fancybox-toolbar {
  visibility: visible;
  opacity: 1;
}

.news__wrapper {
  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.54);

    @include mobile {
      font-size: 16px;
      line-height: 1.4;
    }
  }

  .photogallery {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    a {
      flex: 0 0 100%;
      // margin-right: 3%;
      // margin-bottom: 3%;
      display: block;

      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }
  }
}

.share__block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;

  @include mobile {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #0d47a1;
    padding-right: 10px;

    @include mobile {
      font-size: 16px;
      line-height: 1.3;
    }
  }

  a {
    margin-left: 10px;
    display: inline-block;

    img {
      @include mobile {
        max-width: 25px;
      }
    }

    &:hover {
      transform: translateY(-3px);
    }
  }
}

.map__contact {
  height: 420px;
  width: 100%;
  background-color: #ccc;

  @include mobile {
    height: 300px;
  }
}

.contact__page__context {
  h2 {
    text-align: left;
  }

  ul {
    li {
      margin-bottom: 10px;

      a {
        display: inline-flex;
        align-items: center;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: rgba(0, 0, 0, 0.54);

        @include desk {
          font-size: 16px;
        }

        &:hover {
          color: #0d47a1;
        }

        img {
          margin-right: 12px;
          width: 22px;
          height: 22px;
          object-fit: contain;
          object-position: center;
          @include desk {
            max-width: 18px;
          }
        }
      }
    }
  }
}

.my__accordion {
  .card--item {
    background: #ffffff;
    box-shadow: 0px 3px 49px 9px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .card__header {
    display: flex;
    align-items: center;
    padding: 18px 5px;

    button {
      background: none;
      width: 100%;
      text-align: left;
      display: inline-flex;
      align-items: center;
      font-size: 18px;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.87);
      position: relative;

      &.collapsed {
        span.down {
          transform: rotate(-180deg);
        }
      }

      &:after {
        content: "";
        position: absolute;
        left: -24px;
        background: #f7f8f9;
        box-shadow: 0px 0px 49px 2px rgba(0, 0, 0, 0.19);
        border-radius: 8px;
        width: 48px;
        height: 48px;
        z-index: 1;

        @include ipad_pro {
          left: -10px;
        }

        @include mobile {
          left: -24px;
        }
      }
    }

    span {
      display: inline-block;
      margin-right: 45px;

      &.down {
        background: #ffffff;
        width: 30px;
        height: 30px;
        display: inline-flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.09);
        z-index: 2;
        transform: rotate(-90deg);
        transition: all 0.35s;

        img {
          width: 10px;
          height: auto;
        }
      }
    }
  }

  .card-body {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.5);
    padding-left: 85px;

    @include mobile {
      padding: 10px;
      padding-left: 30px;
      padding-bottom: 20px;
    }
  }
}

.document__wrapper {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 3px 49px 9px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 30px 15px 30px 5px;
    position: relative;

    @include mobile {
      flex-wrap: wrap;
      padding-left: 10px;
    }

    &:after {
      content: "";
      position: absolute;
      left: -24px;
      width: 74px;
      top: 10px;
      bottom: 10px;
      background: #f2f4fb;
      box-shadow: 0px 0px 49px -2px rgba(0, 0, 0, 0.19);
      border-radius: 8px;
      z-index: 1;

      @include mobile {
        display: none;
      }
    }
  }

  .file__icon {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    margin-right: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    background: #fff;

    @include mobile {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
      flex: 0 0 40px;
      position: absolute;
      top: 0;
      transform: translateY(-50%);
    }

    img {
      @include mobile {
        width: 16px;
        height: auto;
      }
    }
  }

  .title {
    h4 {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.87);
      width: 385px;
      a {
        font-size: 15px;
        color: #0d47a1;
        text-transform: lowercase;
        margin-left: 5px;
      }
      @include mobile {
        font-size: 16px;
        line-height: 1.3;
        margin-bottom: 10px;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .download-b,
  .calendar-b {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 10px;
    min-width: 70px;

    img {
      margin-bottom: 15px;
    }

    span {
      white-space: nowrap;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .btn-details {
    margin-left: 10px;

    @include mobile {
      margin-top: 10px;
    }
  }

  .item__body {
    background: #ffffff;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px 60px;
    position: relative;
    z-index: 2;
    max-height: 500px;
    overflow-y: auto;
    transition: all 0.15s;

    @include mobile {
      padding: 10px;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #cee8fc;
    }

    &::-webkit-scrollbar-thumb {
      background: #a8d6fa;
      border-radius: 10px;
    }

    h5 {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 5px;
      padding-top: 20px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      display: flex;
      align-items: center;

      color: rgba(0, 0, 0, 0.5);

      @include mobile {
        font-size: 14px;
      }
    }

    .comment-main {
      border-bottom: 1px dashed #b7b7b7;

      & + .comment-main {
        margin-top: 10px;
      }
    }
  }

  .group-comment {
    margin-bottom: 30px;

    .item {
      margin-bottom: 0;
    }
  }
}

.bottom-doc {
  display: flex;
  margin-top: 10px;
  align-items: center;

  @include mobile {
    margin-bottom: 10px;
  }

  a {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #2196f3 !important;
    margin-right: 20px;
    display: inline-block;
    cursor: pointer;
  }
}

span.card-text {
  p {
    display: inline !important;
    padding: 0 !important;
  }
}

.tender__page__context {
  .text-card-style {
    background: #f9f9f9;
    border-radius: 15px;
    padding: 15px 40px 40px;
    margin-bottom: 30px;

    @include mobile {
      padding: 15px 20px;
    }

    &.may_pet {
      p {
        padding: 0;
      }
    }

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .btn-details {
      float: right;
    }
  }
}

.modal-content {
  background: #ffffff;
  border: 1px solid #cecaca;
  box-shadow: 0px 1px 24px 8px rgba(20, 20, 20, 0.08);
  border-radius: 48px;

  @include mobile {
    border-radius: 16px;
  }
}

#tenderModal {
  .modal-dialog {
    max-width: 800px;

    @include mobile {
      max-width: 95%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.tender-modal {
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 160%;
    letter-spacing: 0.02em;
    margin-bottom: 15px;
    color: #000;

    @include desk {
      font-size: 20px !important;
      line-height: 1.3 !important;
      padding-top: 12px;
    }

    @include mobile {
      font-size: 18px !important;
      line-height: 1.3 !important;
      padding-top: 12px;
    }
  }

  .time {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #808080;
    border-bottom: 1px dashed #000000;
    margin-bottom: 30px;
    padding-bottom: 15px;

    @include desk {
      font-size: 14px;
      margin-bottom: 15px;
      padding-bottom: 10px;
    }

    img,
    svg {
      margin-right: 10px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.87);
    @include desk {
      font-size: 13px;
    }
  }
}

.with__project {
  p {
    span {
      color: #0d47a1;
    }
  }
}

.projects_tab {
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    @include ipad {
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    @include mobile {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-top: 20px;
    }

    li {
      margin-right: 30px;
      width: 100%;

      @include desk {
        margin-right: 20px;
      }

      @include ipad {
        margin-right: 0;
        margin-bottom: 35px;
        max-width: calc(50% - 7.5px);
      }

      @include mobile {
        max-width: 100%;
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        padding: 16px 40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        color: #0d0d0d;
        position: relative;

        @include desk {
          font-size: 18px;
        }

        @include ipad_pro {
          font-size: 16px;
          padding: 5px;
        }

        @include mobile {
          font-size: 14px;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 2px;
          transition: all 0.15s;
          background: #9bd2ff;
        }

        b {
          background: #9bd2ff;
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-weight: 700;
          font-size: 12px;
          text-align: center;
          color: #000000;
          margin-left: 3px;
          transform: translateY(-25px);
          margin-bottom: -30px;

          @include mobile {
            width: 20px;
            height: 20px;
            font-size: 10px;
          }
        }

        &.active {
          @include mobile {
            color: #0f4c82;
          }

          &:after {
            height: 5px;
          }
        }
      }
    }
  }
}

.lenta__main {
  a {
    box-shadow: 0px 4px 26px 1px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: block;
    overflow: hidden;
    margin-bottom: 30px;

    @include ipad_pro {
      border-radius: 15px;
    }

    @include mobile {
      border-radius: 12px;
    }

    img {
      max-width: 100%;
      object-fit: cover;
      height: 280px;
      width: 100%;

      @include ipad_pro {
        height: 200px;
      }

      @include mobile {
        height: 140px;
      }
    }
  }
}

.det {
  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #0f4c82;
    margin-bottom: 10px;

    @include desk {
      font-size: 20px;
      line-height: 1.2;
    }
    @include mobile {
      font-size: 16px;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 4px;
      margin-right: 4px;
      background: #0f4c82;
      transform: translateY(-10px);
      @include desk {
        transform: translateY(-5px);
        width: 16px;
      }
    }
  }

  p {
    font-size: 24px;
    line-height: 145.18%;
    color: #646668;
    margin-bottom: 16px;
    @include desk {
      font-size: 16px;
      line-height: 1.2;
    }
    @include mobile {
      font-size: 14px;
    }
  }
}

.block-real {
  margin-top: 50px;
  margin-bottom: 50px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #2196f3;
    border-radius: 64px;
    padding: 20px 30px;
    @include desk {
      margin-bottom: 10px;
      margin: 5px 40px;
    }
    @include mobile {
      margin-bottom: 10px;
    }

    img {
      margin-bottom: 10px;
    }

    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: 0.02em;
      color: #0f4c82;
      margin-bottom: 8px;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #0d47a1;
    }
  }
}

.flexible {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 3;

  .time,
  .count-comment {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #ffffff;

    @include mobile {
      font-size: 16px;
    }

    img {
      margin-right: 10px;
    }
  }
}

.btn-secondary {
  color: #fff;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent !important;
}

.close__collapse {
  margin-top: 30px;
  margin-bottom: 10px;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: #2196f3;
  }
}

// .mt-20 {
//   margin-top: 15rem;
// }
.spinners {
  position: absolute;
  top: 50%;
  left: 47%;
}

.spinner_item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@keyframes jumping {
  0% {
    transform: translate(0, 0);
  }

  // 25% {
  //   transform: translate(0, -1rem);
  // }

  100% {
    transform: translate(0, -1rem);
  }
}

.weater-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  // margin-right: 30px !important;
  // margin-left: 40px !important;
  height: 50px;

  img {
    padding: 5px;
  }

  p {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin-bottom: 0rem;
    // flex-direction: column;
    padding: 0 5px;
    // width: 50%;
  }

  .div-weater {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.swip-weater-wrap {
  width: 150px;
}

@media (max-width: 600px) {
  .spinners {
    position: absolute;
    top: 50%;
    left: 45%;
  }
}

@media (max-width: 400px) {
  .spinners {
    position: absolute;
    top: 50%;
    left: 42%;
  }
}
.mobile-search-form {
  display: none !important;
}
.desctop-search-form {
  display: block;
}
@media (max-width: 800px) {
  .mobile-search-form {
    display: flex !important;
  }
  .desctop-search-form {
    display: none !important;
  }
}

@media (max-width: 400px) {
  .mobile-search-form {
    display: flex !important;
  }
  .desctop-search-form {
    display: none !important;
  }
}
.dropdown-menu {
  display: block;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateY(20%);
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(-3%);
  pointer-events: auto;
}

section.contact_us {
  .containers {
    @include desk {
      max-width: 1200px;
    }
  }
}

body {
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #002444;
    border-radius: 0px;
  }

  .add--new {
    .card.card-news {
      box-shadow: none;
    }

    .card-body {
      padding-top: 10px;
      padding-bottom: 20px;
    }

    .card-text {
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;
      border-bottom: 0 !important;
      height: auto !important;

      a {
        font-size: 14px;
        padding-left: 30px;
        padding-right: 30px;
        display: inline-block;
      }
    }

    .card-img-top {
      text-align: center;
      padding: 40px 0;

      img {
        width: 100px;
        height: auto;
        object-fit: contain !important;
        object-position: center;
      }
    }
  }
}

.obejctCover {
  object-fit: cover !important;
}
.hdr__top .row {
  @include ipad {
    display: none;
  }
}
.hdr__top .swiper {
  @include mobile {
    display: none !important;
  }
}
